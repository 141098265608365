$spacing-unit: 4px;

$spacing-xxs: $spacing-unit; // 4px
$spacing-xs: 2 * $spacing-unit; // 8px
$spacing-s: 3 * $spacing-unit; // 12px
$spacing-m: 4 * $spacing-unit; // 16px
$spacing-l: 6 * $spacing-unit; // 24px
$spacing-xl: 8 * $spacing-unit; // 32px
$spacing-xxl: 10 * $spacing-unit; // 40px
$spacing-xxxl: 12 * $spacing-unit; // 48px
$spacing-xxxxl: 16 * $spacing-unit; // 64px

$border-radius: $spacing-unit;

$max-width: 1024px;

// TODO: move this to a more appropriate place
$multilisting-height-desktop: 16px;
$multilisting-height-mobile: 19px;
